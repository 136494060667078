'use client';

import { ApiRequestContext } from '@ocodelib/api-common';
import { useDebounceEffect, useDeepCompareMemo } from '@ocodelib/mui/hooks';
import { isNil } from '@ocodelib/util/misc';
import { useRef } from 'react';

interface DebounceOptions {
  wait?: number;
  leading?: boolean;
  trailing?: boolean;
  maxWait?: number;
}

type Callback =
  | ((ctx: ApiRequestContext) => VoidFunction | undefined)
  | ((ctx: ApiRequestContext) => void);

export function useApiEffect(
  callback: Callback,
  deps: unknown[],
  debounceOptions?: DebounceOptions,
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const debounceOpts = useDeepCompareMemo(() => {
    if (
      isNil(debounceOptions) ||
      (isNil(debounceOptions?.wait) &&
        isNil(debounceOptions?.leading) &&
        isNil(debounceOptions?.trailing) &&
        isNil(debounceOptions?.maxWait))
    ) {
      return { wait: 0 };
    }
    return debounceOptions;
  }, [debounceOptions]);

  useDebounceEffect(
    () => {
      const ctx = ApiRequestContext.of();
      const close: any = callbackRef.current(ctx);
      return () => {
        ctx.cancel();
        if (typeof close === 'function') {
          close();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
    debounceOpts,
  );
}
